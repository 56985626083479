import React, {useRef, useState, useEffect} from 'react';
import PropTypes from "prop-types";
import { Icon } from '@iconify/react';
import useOnScreen from './useOnScreen';


const StartToFinishSection = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  // Run this effect when isVisible changes
  useEffect(() => {
    if (isVisible && !animationPlayed) {
      setAnimationPlayed(false); // Set the animation as played
    }
  }, [isVisible, animationPlayed]);

  return (
    <div
    id="start-to-finish"
      ref={ref}
      className={`w-full flex flex-col items-center h-screen  justify-center relative px-4`}
    >
      <div className="w-full md:pt-24 flex flex-col items-center container transition-[max-width] px-5 md:px-10 duration-500 space-y-32 h-full justify-center relative">
        <h3>
          Desde el productor al consumidor en casa
        </h3>
        {/**Icons and Lines */}
        <div className="relative flex items-center w-full">
          {/**<div className='container grid grid-cols-12 items-center transition-[max-width] duration-500 relative px-6 sm:mx-auto sm:px-10'> */}
          <div className='container grid grid-cols-12 w-full items-center transition-[max-width] duration-500 relative mx-auto '>
            {/* Factory Icon */}
            <div className="flex col-start-1 col-span-2  sm:col-span-1 justify-start z-20">
              <Icon icon="ic:round-factory" className="w-8 h-8 md:w-16 md:h-16 text-green-haze-500" />
            </div>

            {/* Discontinuous Line*/}
            <div className="absolute bottom-0 inset-x-0 col-start-1 col-span-12 border-t-2 border-dashed border-green-haze-950" />

            {/* Company Icon */}
            <div className="col-start-6 col-span-2 translate-y-0 flex justify-center z-10">
              <Icon icon="fluent:building-48-filled" className="w-8 h-8 md:w-16 md:h-16 text-green-haze-500" />
            </div>

            {/* Home Icon */}
            <div className="col-start-11 translate-y-1 sm:col-start-12 col-span-2 sm:col-span-1 flex justify-end z-10">
              <Icon icon="ph:house-fill" className="w-8 h-8 md:w-16 md:h-16 text-green-haze-500" />
            </div>

            {/* Moving Truck */}
            {/**style={{ animation: 'moveTruck 5s linear infinite' }} */}
            <div className="absolute -bottom-1 z-10  left-0 " style={{ animation: isVisible && !animationPlayed ? 'moveTruck 5s ease-in-out forwards' : 'none',}}>
              <Icon icon="fa6-solid:truck" className="w-8 h-8 md:w-12 md:h-12 text-green-haze-950" />
            </div>
          </div>
        </div>
        <p className="text-center sm:max-w-[80ch]">
          Escaneando el código QR y gracias a nuestra interfaz web dedicada el consumidor puede obtener toda la 
          información sobre la cadena de suministro de cualquier producto.
        </p>
      </div>
      {/* Inline style for custom animation */}
      <style>
            {`
              @keyframes moveTruck {
                0% { left: 0; }
                100% { left: calc(100% - 2rem); transform: translateX(-100%); }
              }              
            `}
      </style>
    </div>
  )
}

//Used for type checking the props that are passed to the SectionFromStart component in React. It helps to ensure that the values received by the component 
//props are of the expected type, and it can help catch common bugs during development.
StartToFinishSection.propTypes = {
  property1: PropTypes.oneOf(["animation-end", "default"]),
  frame: PropTypes.string,
};

export default StartToFinishSection