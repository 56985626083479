import React, { useState, useEffect, useRef } from 'react';
// import imgBlockchainDesktop from './Animation-blockchain_desktop.svg'
// import imgBlockchainMobile from './Animation-blockchain_mobile.svg'
// import imgBlockchainStart1 from '../../assets/sectionBlockchain/animation-blockchain-start.svg';
// import imgBlockchainFinish1 from '../../assets/sectionBlockchain/Animation-blockchain.svg';
// import imgBlockchainStart from '../../assets/sectionBlockchain/AnimationBlockchainStart';
// import imgBlockchainFinish from '../../assets/sectionBlockchain/AnimationBlockchainFinish';
//import { ReactComponent as AnimImg } from '../../assets/sectionBlockchain/animation-blockchain-start.svg';
// import { useSpring, animated, useChain, useSpringRef, useTransition  } from '@react-spring/web';
// import { SwitchTransition, CSSTransition } from 'react-transition-group';
import "./fade.css";
import BlockchainImage from './BlockchainImage' 

const BlockchainSection = () => {

  // const [currentImage, setCurrentImage] = React.useState(0);
  // const imageRef = React.useRef(null);
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Trigger when at least 50% of the element is in the viewport
    );
    const currentRef = containerRef.current; // Store the current value of containerRef.current

    if (currentRef) {
      observer.observe(currentRef);
      console.log("currentRef", currentRef);
    }
    
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  console.log("isInView", isInView);

  // const switchImage = () => {
  //   setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  // };  
  
  return (
  <div id="blockchain" className="relative px-5 container transition-[max-width] duration-500 sm:mx-auto sm:px-10"> {/**w-screen */}
    <div className="grid h-screen w-full grid-cols-12 grid-rows-12 gap-x-0.5">
      <div className="text-left col-span-10 col-start-1 row-span-3 justify-end row-start-2 mb-10 flex md:space-y-6 space-y-3 flex-col">
        <h3 className="text-left">Blockchain para garantizar la seguridad de los datos</h3>
      </div>
        <ul className="col-span-10 md:col-span-5 md:col-start-1 col-start-1 row-span-2 row-start-2 md:row-start-5 list-outside pl-6 list-disc space-y-2 md:space-y-4 text-left text-base">
          <li>
          Facilitación de certificaciones y regulaciones con pasaportes digitales
          </li>
          <li>
          Mejora del control de calidad con trazabilidad en tiempo real
          </li>
          <li>
          Verificación inmutable de cada paso del proceso.
          </li>
        </ul>
      <div ref={containerRef} className=" col-span-11 col-start-2 md:col-span-8 md:col-start-4 row-span-6 row-start-7 md:row-span-8 md:row-start-5 flex items-center justify-center text-blue-600">  
        <BlockchainImage isInView={isInView} /> 
      </div>
      <div className="pointer-events-none user-select-none col-span-6 col-start-7 row-span-9 row-start-1 md:row-start-1 md:row-span-8 -my-px rotate-180 rounded-tl-xl border-l-2 border-t-2 border-dashed border-curious-blue-500"></div>
      <div className="pointer-events-none user-select-none col-span-5 col-start-2 row-span-3 row-start-10 md:row-start-9 md:row-span-5 -my-px rounded-tl-xl border-l-2 border-t-2 border-dashed border-curious-blue-500"></div>
    </div>
  </div>
  )
}

export default BlockchainSection