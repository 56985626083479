import React from 'react'
import ReactECharts from 'echarts-for-react';

const ChartBarCO2 = () => {

    const co2Data = {
        categories: ["Chile", "Mexico", "España"],
        values: [13, 10.5, 0.29],
        // values: [13, 0.6, 0.29],
        description: "CO2 generado por kg de aguacate",
        distance: ["12000 km", "10000 km", "Mercado Local"]
    };
    
    const distanceData = {
        categories: ["Chile", "Mexico", "España"],
        values: [12000, 10000, "Mercado Local"],
        description: "Distancia recorrida en km desde lugar de producción"
    };

    // Process the distance data to convert non-numeric values to 0 for the chart
    const processedDistanceData = distanceData.values.map(value => typeof value === 'string' ? 0 : value);

    // Chart options
    const options = {
        // Add custom graphic elements for the source text
        responsive: true,
        maintainAspectRatio: false,
        
        graphic: [
            {
                type: 'text',
                left: 'left',
                bottom: 5, // Position from the bottom of the chart
                style: {
                    text: 'Fuente: Documento WWF',
                    fill: '#333',
                    fontSize: 10
                }
            },
            {
                type: 'text',
                left: 'left',
                bottom: 17, // Position from the bottom, stacked below the first
                style: {
                    text: 'Fuente: Guía ECTA para medir e gestionar la dispersión de CO2',
                    fill: '#333',
                    fontSize: 10
                }
            },
            {
                type: 'text',
                left: 'left',
                bottom: 30, // Position from the bottom, stacked below the second
                style: {
                    text: 'Fuente: Publicación IL FATTO ALIMENTARE Impacto medioambiental del nuevo SuperFood',
                    fill: '#333',
                    fontSize: 10
                }
            }
        ],
        animationDuration: 200,
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            formatter: function(name) {
                if (name === co2Data.description) {
                    return `${name}\n(y distancia recorrida desde el lugar de producción)`;
                }
                return name;
            }
        },
        grid: {
            left: '12%',
            right: '12%',
            // bottom: '3%',
            // containLabel: true
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'value',
            name: 'kg(CO2)'
        },
        yAxis: {
            type: 'category',
            data: co2Data.categories
        },
        series: [
            {
                name: co2Data.description,
                type: 'bar',                
                //data: co2Data.values, //All bars have the same color
                data: co2Data.values.map((value, index) => {
                    return {
                        value: value,
                        itemStyle: {
                            color: index === 0 ? 'red' : index === 1 ? 'blue' : 'green'
                        }
                    };
                }),
                label: {
                    show: true,
                    position: 'right',
                    // Custom formatter to display only the distance text
                    formatter: function(params) {
                        return co2Data.distance[params.dataIndex];
                    }
                }
            },
        ]
    };

  return (
    <div className="flex-1 h-full">
        <ReactECharts option={options} style={{  height: '100%', width: '100%', }} />
    </div>
  )
}

export default ChartBarCO2