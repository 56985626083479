import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap, Popup  } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapIcon from './mdi--map-marker.svg'
import { Icon } from '@iconify/react';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

//Defines a custom icon for the marker
// const defaultIcon = L.icon({
//     iconUrl: icon, //iconUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
//     shadowUrl: iconShadow,
//     iconAnchor: [16, 45], //Anchor point by default is at Leaflet marker's bottom tip
// });

const myIcon = new L.Icon({
    iconUrl: mapIcon,
    iconRetinaUrl: mapIcon,
    iconSize: [50, 50],
    popupAnchor:  [-0, -0],
});


// L.Marker.prototype.options.icon = myIcon;



// This component will update the map's position
// It uses the useMap hook from react-leaflet to access the map instance and then updates the map's view using setView.
function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
}

/**The style={{ zIndex: 1000 }} in the last div is used to ensure that it has a higher z-index
 * than the map layers. The tailwind values were too low.
 */
/** 
 * The MapLocation component renders the ChangeMapView component, passing the current coordinates. 
 * Whenever fromCoordinates changes, ChangeMapView will be re-rendered and will update the map's view to the new coordinates.
*/
// const fromCoordinates = [locations.lat, locations.lng]; // Example: [51.505, -0.09]
// console.log("MapLocation fromCoordinates", fromCoordinates);

const MapLocation = ({ locations, height, width }) => {
  const mapRef = useRef(null);
  // console.log("MapLocation locations", locations);
  if (!locations || !Array.isArray(locations) || locations.length === 0) {
    // Handle the case when locations is not defined or is an empty array
    return (
      <div>
      {/* <div className="relative overflow-clip rounded-md p-2 bg-white h-full w-full"> */}
        {/* <p>No locations available</p> */}
      </div>
    );
  }
  return (
      <div className="relative overflow-clip rounded-md h-full w-full">
          <MapContainer 
              attributionControl={false}
              center={[0, 0]} 
              zoom={3} 
              style={{ height: height, width: width }}
              ref={mapRef}
          >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {/* Render markers for each location */}
              {locations.map((location, index) => (
                // console.log("MapLocation location", location),
                  <Marker icon={myIcon} key={index} position={[location.lat, location.lng]} >
                    <Popup>
                      <div>
                        {location.logo && 
                          <img className="w-2 h-2 object-cover" src={location.logo}></img>
                        }
                        {location.name && 
                        <span className='text-sm'>{location.name}</span>
                        }
                        {/* {location.description && 
                          <span className="text-xs">{location.description}</span>
                        } */}
                      </div>
                    </Popup>
                  </Marker>
              ))}
              
              <ChangeMapView coords={locations[0]} />
          </MapContainer>            
      </div>
  );
};


export default MapLocation;