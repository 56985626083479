import React, {useState, useEffect} from 'react'
import { Icon } from '@iconify/react';
import imgDashboardAnalysis from '../../assets/captura-dashboard-calc.webp';
import imgDashboardEmpresa from '../../assets/captura-dashboard-empresas.webp';
import imgDashboardProductos from '../../assets/captura-dashboard-productos.webp';
import imgDashboardEventos from '../../assets/captura-dashboard-events.webp';


const DashboardService = () => {
  const [description, setDescription] = useState({
    title: "Ubica empresas y origenes", 
    text: "Investiga y localiza empresas y origenes de productos." 
  });
  const [imageSrc, setImageSrc] = useState(imgDashboardEmpresa);
  const [activeButtonType, setActiveButtonType] = useState('empresas'); // Initialize activeButtonType
  

  const handleButtonClick = (buttonType) => {
    setActiveButtonType(buttonType); 
    switch (buttonType) {
      case 'calculadora':
        setDescription({
          title: "Calcula tu impacto", 
          text: "Nuestro dashboard permite analizar diversos parámetros para comprobar que su producto produce el menor impacto ambiental." 
        });
        setImageSrc(imgDashboardAnalysis); // Replace with actual screenshot path
        break;
      // case 'rastreo':
      //   setDescription({
      //     title: "Rastrea todo", 
      //     text: "Nuestro dashboard permite rastrear todos los actores de la cadena de valores." 
      //   });
      //   setImageSrc(imgDashboardTrack); // Replace with actual screenshot path
      //   break;
      case 'eventos':
        setDescription({
          title: "Rastrea todo", 
          text: "Nuestro dashboard permite rastrear todos los actores de la cadena de valores." 
        });
        setImageSrc(imgDashboardEventos); // Replace with actual screenshot path
        break;
      case 'empresas':
        setDescription({
          title: "Ubica empresas y origenes", 
          text: "Investiga y localiza empresas y origenes de productos." 
        });
        setImageSrc(imgDashboardEmpresa); // Replace with actual screenshot path
        break;
      // case 'instalaciones':
      //   setDescription({
      //     title: "Ubica tus pedidos", 
      //     text: "Nuestro dashboard permite consultar información de cualquier productor." 
      //   });
      //   setImageSrc(imgDashboardEmpresa); // Replace with actual screenshot path
      //   break;
      // case 'mapas':
      //   setDescription({
      //     title: "Ubica empresas y origenes", 
      //     text: "Nuestro dashboard permite localizar de forma intuitiva a cualquier productor." 
      //   });
      //   setImageSrc(imgDashboardProductos); // Replace with actual screenshot path
      //   break;
      case 'productos':
        setDescription({
          title: "Investiga productos", 
          text: "Nuestro dashboard permite consultar información de cualquier paso del producto." 
        });
        setImageSrc(imgDashboardProductos); // Replace with actual screenshot path
        break;
      default:
        setDescription('');
        setImageSrc('');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      switch (activeButtonType) {
        case 'calculadora':
          handleButtonClick('empresas');
          break;
        case 'eventos':
          handleButtonClick('calculadora');
          break;
        case 'empresas':
          handleButtonClick('productos');
          break;
        case 'productos':
          handleButtonClick('eventos');
          break;
        default:
          handleButtonClick('empresas');
          break;
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [activeButtonType]);

  return (
    <div id="dashboard" className="relative overflow-clip w-full bg-gre en-haze-700 "> 
      <div className="grid container transition-[max-width] duration-500 px-5 sm:mx-auto sm:px-10 h-screen w-full grid-cols-12 grid-rows-12 gap-x-0.5">
        <div className="col-span-5 pointer-events-none user-select-none col-start-2 row-span-2 md:row-span-10 md:row-start-1 row-start-1 -my-px rounded-bl-xl border-b-2 border-l-2 border-dashed border-curious-blue-500"></div>
        <div className="col-span-6 pointer-events-none user-select-none col-start-7 row-span-10 md:row-span-2 md:row-start-11 row-start-3  -my-px rotate-180 rounded-bl-xl border-b-2 border-l-2 border-dashed border-curious-blue-500"></div>
        <div className="col-span-10 col-start-2 row-span-3 row-start-4 md:col-span-4 md:col-start-3 md:row-span-6 md:row-start-4 flex flex-col items-left justify-center pt-16 md:p-4">
          <h3 className="font-bold text-left  text-2xl md:text-3xl font-serif pb-4">{description.title}</h3>
          <p> {description.text}</p>        
        </div>
        <div className="z-10 flex justify-center items-center col-span-9 col-start-3 row-span-2 row-start-2 md:col-span-2 md:col-start-1 md:row-span-8 md:row-start-4 lg:row-start-4 lg:col-start-1 lg:col-span-2 lg:row-span-6 ">
          <div className="w-full min-h-20 md:w-auto rounded-lg space-x-2 sm:space-y-2 sm:space-x-0 border-2 bg-white/30 backdrop-blur-sm flex flex-row justify-around items-center md:flex-col p-2 sm:p-4">
            <button className={`hover:text-curious-blue-400 transition-colors duration-200 w-full h-12 md:h-full md:max-h-20 flex flex-col items-center justify-center" ${activeButtonType === 'empresas' ? 'text-curious-blue-500' : 'text-curious-blue-800'}`} onClick={() => handleButtonClick('empresas')}>
              <Icon icon="mdi:company" className='w-full h-full aspect-square md:w-16'/>
              <span className="text-xs sm:text-base">Empresas</span>
            </button>
            <button className={`hover:text-curious-blue-400 transition-colors duration-200 w-full h-12 md:h-full md:max-h-20 flex flex-col items-center justify-center" ${activeButtonType === 'productos' ? 'text-curious-blue-500' : 'text-curious-blue-800'}`} onClick={() => handleButtonClick('productos')}>
              <Icon icon="gridicons:product"  className='w-full h-full aspect-square md:w-16'/>
              <span className="text-xs sm:text-base">Productos</span>
            </button>
            <button className={`hover:text-curious-blue-400 transition-colors duration-200 w-full h-12 md:h-full md:max-h-20 flex flex-col items-center justify-center" ${activeButtonType === 'eventos' ? 'text-curious-blue-500' : 'text-curious-blue-800'}`} onClick={() => handleButtonClick('eventos')}>
              <Icon icon="material-symbols:event-note"  className='w-full h-full aspect-square md:w-16'/>
              <span className="text-xs sm:text-base">Eventos</span>
            </button>
            <button className={`hover:text-curious-blue-400 transition-colors duration-200 w-full h-12 md:h-full md:max-h-20 flex flex-col items-center justify-center ${activeButtonType === 'calculadora' ? 'text-curious-blue-500' : 'text-curious-blue-800'}`} onClick={() => handleButtonClick('calculadora')}>
              <Icon icon="mdi:calculator" className='w-full h-full aspect-square md:w-16' />
              <span className="text-xs sm:text-base">Calculadora</span>
            </button>
            
          </div>
        </div>
        <div className='flex w-full h-full justify-start col-start-2 mx-auto md:col-start-7 row-start-8 md:row-start-4 row-span-5 md:row-span-8 col-span-10 md:col-span-6'>
          <div className='absolute -right-2 md:right-2 left-2 md:left-1/2  h-fit  pb-4 flex place-self-end aspect-[144/80]'>
            <img  
              className="w-auto rounded-lg h-full object-left shadow-black/10 shadow-[0_3px_8px_5px] md:shadow-black/10 md:shadow-[0_3px_12px_5px] object-contain"
              src={imageSrc}
              alt="Dashboard Screenshot" 
            />
          </div>
        </div>
        {/* <div className='flex w-full h-full justify-start col-start-2 mx-auto md:col-start-7 row-start-8 md:row-start-4 row-span-5 md:row-span-8 col-span-10 md:col-span-6'>
          <div className='relative w-fit pb-4 flex h-full h-[ca lc(120%)] md:h-full aspect-[144/81]'>
            <img  
              className="w-auto rounded-lg h-full object-left shadow-black/10 shadow-[0_3px_8px_5px] md:shadow-black/10 md:shadow-[0_3px_12px_5px] object-contain"
              src={imageSrc}
              alt="Dashboard Screenshot" 
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}


export default DashboardService