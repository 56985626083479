// src/components/ChartBar.jsx

import React, { useState, useEffect }  from 'react';
//import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartBar = ({ jsonFilePath  }) => {
const [chartData, setChartData] = useState({ categories: [], values: [] }); 

  // Define the data from your JSON
  const jsonData = {
    categories: ["Competidores", "Guacaguaca"],
    values: [600, 300],
    description: ["Agua usada por kg de aguacate"]
  };

//const [chartData, setChartData] = useState(jsonData); 
//Test
/**
 * fetch(jsonFilePath)
      .then((response) => {
        console.log("fetch response",response);
        return response.json();
      })
      .then((responseData) => {
        //setChartData(data);
        console.log("fetch data",responseData);
        //console.log("fetch data categories",data.categories);
        console.log("fetch chartData",chartData);
        return responseData;
        })
        .then(data => {
            this.setChartData(data);
            console.log("setChartData",data);
        })
      .catch((error) => console.error('Error fetching data:', error));
 */
/**
 * .then(text => {
        console.log("Response body:", text);
        // After checking the response, change this back to response.json()
      })
 */
const getData = () => {
    fetch(jsonFilePath)
      .then((response) => {
        // console.log("fetch response",response);
        return response.json();
        //return response.text();  // Temporarily use text() to see the actual response
      })      
      .then((data) => {
        setChartData(data);
        // console.log("fetch data",data);
        //console.log("fetch data categories",data.categories);
        // console.log("fetch chartData",chartData);
        })        
      .catch((error) => console.error('Error fetching data:', error));
}
useEffect(() => {
    getData()
  }, []);

  /*const fetchData = async () => {
    try {
        const response = await fetch (jsonFilePath);
        console.log("response",response);
        console.log("jsonFilePath",jsonFilePath);
        if(!response.ok) {
            throw new Error(`HTTP error! Status: $(response.status)`);
        }
        const data = await response.json();
        console.log("data",data);
        setChartData(data);
        console.log("fetch chartData",chartData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }        
};*/
    //console.log("jsonFilePatch typeof",typeof jsonFilePath, jsonFilePath);

  //console.log("chartData",chartData);
  //console.log("jsonFilePath",jsonFilePath);

  // Conditional rendering
  if (!chartData.categories.length || !chartData.values.length) {
    return <div>Loading...</div>; // Placeholder for loading state
  }
  // Define colors for each bar
  const barColors = ['#FF5733', '#33FF57', '#3357FF'];

  // Map values to include colors
  const seriesData = chartData.values.map((value, index) => ({
    value: value,
    itemStyle: {
      color: barColors[index]
    }
  }));
  //chartbar options
  const options = {
    title: {
      text: chartData.description,
    },
    responsive: true,
    maintainAspectRatio: false,
    xAxis: {
      type: 'category',
      data: chartData.categories,//['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
    yAxis: {
      type: 'value',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
  },
    series: [
      {
        //data: chartData.values,//[120, 200, 150, 80, 70],
        data: seriesData,
        type: 'bar',
        barWidth: '40%', // Set the width of the bars
        /*itemStyle: {
          color: '#74c475', // Set the color of the bars
        },*/
      },
    ],
  };

    return (
      <div className="flex-1 h-full">
      <ReactECharts option={options} style={{  height: '100%', width: '100%', }} />
        </div>
    );
};

export default ChartBar;