import React from 'react'
import { NavbarSection, FooterSection, Section2Header, StartToFinishSection, Compare, DataAndDash, BlockchainSection, DashboardService, ConsumerSection, ProductSection, Feedback, Guacaguaca, BlogSection} from '../components';

//BlockchainSection backgroundLine={background} vector={vector}
/**
 * DashboardService
 * background={background1}
    backgroundLine={backgroundLine1}
    list={list1}
 *  group={group14}
    screenshot={screenshotPic}
    timeline={timeline1}
    upload={upload1}
 */
const home = () => {
  return (
    <div className="bg-lime-zero-100 flex flex-col min-h-screen">
      <NavbarSection />
      {/**Add background color bg-lime-zero-100 because from blockchain to product there was a very thin margin to the left and right */}
      <main className="flex-grow">
        {/* Add your sections here with ids matching the navbar links */}
        <Section2Header/>
        <StartToFinishSection/>
        <Guacaguaca/>
        <Compare/>
        <DataAndDash/>
        <DashboardService/>      
        <BlockchainSection/>
        <ConsumerSection/>     
        <ProductSection/>                
        <Feedback/>        
        <BlogSection/>      
      </main>
      <FooterSection />
    </div>
  )
}

export default home