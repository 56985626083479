import React, {useState} from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';


const FeedbackSection = () => {
  const [submitStatus, setSubmitStatus] = useState({ success: false, error: false });

  //
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Solo se permite letras')
      .required('No puedes dejar vacío este campo'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Solo se permite letras')
      .required('No puedes dejar vacío este campo'),
    email: Yup.string()
      .email('Invalid email address')
      .matches(/^[A-Za-z0-9@._]*$/, 'Solo se permite letras, números, @, . y _')
      .required('No puedes dejar vacío este campo'),
    occupation: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Solo se permite letras'),
    message: Yup.string()
      .matches(/^[A-Za-z0-9 ]*$/, 'Solo se permite letras y números')
      .required('No puedes dejar vacío este campo'),
  });

  //
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      occupation: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      //Send data to the serverless function
      axios.post('api/sendFeedback', values, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          console.log("Response",response.data);
          setSubmitStatus({ success: true, error: false });          
        })
        .catch(error => {
          console.error("Error",error);
          setSubmitStatus({ success: false, error: true });
        })
        .finally(() => {
          setSubmitting(false); //Finish submission process
          resetForm(); //Optionally reset the form
        })
      },
      
  });
    
  return (
    <div id="feedback" className="h-screen md:h-auto bg-green-haze-700 relative z-10">
      <div className="flex h-full container  px-5 py-5 md:px-10 mx-auto space-y-8 md:space-y-0 md:space-x-4 flex-col md:flex-row overflow-hidden"> 
        
        {/* Div Izquierdo */}
        <div className=" w-full mt-auto md:mt-0 space-y-4 overflow-hidden flex flex-col justify-center items-start">
          <h3 className="text-lime-zero-100 text-left">Conocenos mejor</h3>
          <p className="text-lime-zero-100">Deja tu detalles de contacto para que te informamos o infórmate sobre nuestro proyecto en nuestras redes sociales.</p>
          <div className="inline-flex text-2xl justify-start gap-x-3">
              <a href="https://www.facebook.com" className="text-lime-zero-100 hover:text-green-haze-400 transition-colors">
                <Icon icon="ri:facebook-fill" />
              </a>
              <a href="https://www.instagram.com" className="text-lime-zero-100 hover:text-green-haze-400 transition-colors">
                <Icon icon="ri:instagram-line" />
              </a>
              <a href="https://www.twitter.com" className="text-lime-zero-100 hover:text-green-haze-400 transition-colors">
                <Icon icon="ri:twitter-line" />
              </a>
            </div>
        </div>
        
        {/* Div Derecho */}
        <div className="py-2 md:p-4 pb-0 md:pb-0  flex flex-col justify-end items-center md:items-start w-full">
          {/* <h2 className="pt-5 pb-2 text-green-haze-900 text-[24px] font-semibold text-left">Feedback</h2> */}
          <form onSubmit={formik.handleSubmit} className="space-y-3 -mb-5 bg-lime-zero-50 pt-4 pb-12 px-6 rounded-t-xl w-full md:max-w-[32rem] ml-auto">
            
            {/**Checks if the form is sent empty and if so sends a warning message */}
            {formik.submitCount > 0 && !Object.keys(formik.touched).length && !Object.keys(formik.errors).length ? (<div className="text-red-600">Por favor rellena todos los campos</div>) : null}
            {/* Nombre */}
            <div className="text-left space-y-1">
              <label htmlFor="firstName" className="block text-green-haze-900 text-left">Nombre</label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                className="w-full px-2.5 py-1.5 border-2 border-green-haze-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-green-haze-500 focus:text-green-haze-900 bg-transparent text-green-haze-950"
              />
              {formik.touched.firstName && formik.errors.firstName ? (<div className="text-red-600">{formik.errors.firstName}</div>) : null}
            </div>
            
            {/* Apellidos */}
            <div className="text-left space-y-1">
              <label htmlFor="lastName" className="block text-green-haze-900 text-left">Apellido(s)</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                className="w-full px-2.5 py-1.5 border-2 border-green-haze-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-green-haze-500 focus:text-green-haze-900 bg-transparent text-green-haze-950"
              />
              {formik.touched.lastName && formik.errors.lastName ? (<div className="text-red-600">{formik.errors.lastName}</div>) : null}
            </div>
            
            {/* Email */}
            <div className="text-left space-y-1">
              <label htmlFor="email" className="block text-green-haze-900 text-left">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="w-full px-2.5 py-1.5 border-2 border-green-haze-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-green-haze-500 focus:text-green-haze-900 bg-transparent text-green-haze-950"
              />
              {formik.touched.email && formik.errors.email ? (<div className="text-red-600">{formik.errors.email}</div>) : null}
            </div>
            
            {/* Ocupación (Opcional) */}
            <div className="text-left space-y-1">
              <label htmlFor="occupation" className="block text-green-haze-900 text-left">Ocupación
              <span className="text-xs pl-0.5"> (Opcional)</span> </label>
              <input
                id="occupation"
                name="occupation"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.occupation}
                className="w-full px-2.5 py-1.5 border-2 border-green-haze-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-green-haze-500 focus:text-green-haze-900 bg-transparent text-green-haze-950"
              />
              {formik.touched.occupation && formik.errors.occupation ? (<div className="text-red-600">{formik.errors.occupation}</div>) : null}
            </div>
            
            {/* Mensaje */}
            <div className="text-left space-y-1">
              <label htmlFor="message" className="block text-green-haze-900">Mensaje</label>
              <textarea
                id="message"
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                className="w-full px-2.5 py-1.5 border-2 border-green-haze-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-green-haze-500 focus:text-green-haze-900 bg-transparent text-green-haze-950"
              />
              {formik.touched.message && formik.errors.message ? (<div className="text-red-600">{formik.errors.message}</div>) : null}
            </div>
            
            {/* Botón Enviar */}
            <div className='w-full flex justify-end'>
              <button type="submit" className=" border-2 border-green-haze-900 text-green-haze-900 hover:border-green-haze-500 hover:text-lime-zero-100 transition-colors duration-200 inline-flex justify-center py-2 px-8 text-sm font-medium rounded-md hover:bg-green-haze-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-haze-500">
                Enviar
              </button>
            </div>    
          </form>
          {/* <div className="absolute bottom-0 w-full h-8 bg-gradient-to-t from-green-haze-700/20 to-transparent z-10">
</div> */}
          {/* Mensajes de estado del envío del formulario */}
          {submitStatus.success && (
            <div className="text-green-600">Formulario enviado con éxito.</div>
          )}
          {submitStatus.error && (
            <div className="text-red-600">Error al enviar el formulario.</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedbackSection;
