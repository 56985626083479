import React, {useState} from 'react'
import AguacateRama from '../../assets/sectionHeader/Image-Front.png';
import { useStateContext } from '../../contexts/ContextProvider';
import ModalVideo from 'react-modal-video';
import { Link as ScrollLink } from 'react-scroll';

const Section2Header = () => {
  // Add the CO2 and water saved from dashboard
  const { companyCount } = useStateContext();
  const { facilityCount } = useStateContext();
  const [isOpen, setOpen] = useState(false);

  // https://youtu.be/d-yElnJcqlw
  const [videoId, setVideoId] = useState('d-yElnJcqlw');
  return (
    <div id="inicio" className="flex flex-col h-screen md:pt-24 z-[1] overflow-visible w-full items-center relative">
      <div className="container transition-[max-width] px-5 md:px-10 duration-500 flex flex-col items-start p-4 sm:mb-10 justify-end md:justify-end h-full vertical-sm:space-y-4 space-y-6 md:space-y-8 relative w-full">
        <h1 className="w-full md:pr-20 transition-[font-size] h-auto font-serif text-green-haze-500 font-bold vertical-sm:text-5xl text-3xl sm:text-[46px] md:text-[48px] xl:text-7xl leading-snug text-left ">
          Trazabilidad y transparencia:
          <br />
          Del productor al consumidor
        </h1>
        <h2 className=" text-left w-full lg:w-[48ch] transition-[font-size] h-auto font-sans text-curious-blue-600 text-[16px] sm:text-3xl xl:text-4xl vertical-sm:text-2xl !leading-snug">
          Usamos Blockchain para garantizar la veracidad de la información de toda la cadena y aportar información relevante sobre emisiones
        </h2>
        {/**kpi div */}
        <div className="grid grid-cols-2 md:grid-cols-4 sm:max-w-4xl xl:max-w-6xl font-bold justify-start gap-3 mt-10 w-full ">
          {/**First item */}
          <div className="flex flex-col items-start justify-start">
            <div className="uppercase text-left text-green-haze-950 text-xs xl:text-sm  tracking-wide whitespace-nowrap font-sans mb-2">
                Empresas
            </div>
            <div className="font-bold font-serif text-5xl sm:text-7xl xl:text-8xl vertical-sm:text-6xl">
            {companyCount ? companyCount : 2}
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <div className="uppercase text-left text-green-haze-950 text-xs xl:text-sm  tracking-wide whitespace-nowrap font-sans mb-2">
                Instalaciones
            </div>
            <div className="font-bold font-serif text-5xl sm:text-7xl xl:text-8xl vertical-sm:text-6xl">
            {facilityCount ? facilityCount : 5}
            </div>
          </div>
          {/**Second item */}
          <div className="flex flex-col items-start justify-start">
            <div className="uppercase text-left text-green-haze-950 text-xs xl:text-sm  tracking-wide whitespace-nowrap font-sans mb-2 ">
              <span>CO</span><span className="w-fit font-sans align-center text-green-haze-950 text-[7px] xl:text-[9px] h-auto whitespace-nowrap">2</span> <span>ahorrada</span>
            </div>
            <div className="font-bold font-serif text-5xl sm:text-7xl xl:text-8xl vertical-sm:text-6xl">
                187
            <span className="text-2xl sm:text-3xl xl:text-4xl">T</span>
            </div>
          </div>
          {/**Third item */}
          <div className="flex flex-col items-start justify-start">
              <div className="uppercase text-left text-green-haze-950 text-xs xl:text-sm  tracking-wide whitespace-nowrap font-sans mb-2">
                Agua ahorrada
              </div>
            <div className="font-bold font-serif text-5xl sm:text-7xl xl:text-8xl vertical-sm:text-6xl"> {/** leading-[normal] */}
              {/* Add real data? */}
              56<span className="text-2xl sm:text-3xl xl:text-4xl">L</span>
            </div>
          </div>
        </div>
        <div class="flex flex-row space-x-4 w-full ">

          <ScrollLink 
              key="start-to-finish"
              to="start-to-finish"
              smooth={true} 
              duration={500} 
              className="w-1/2 sm:w-auto px-4 py-2 vertical-sm:py-2 vertical-sm:px-4 sm:text-lg lg:text-xl lg:px-6 lg:py-3 rounded-md border-solid border-2 bg-lime-zero-100 border-green-haze-600 hover:bg-green-haze-500 hover:text-white hover:border-green-haze-500 transition-colors text-green-haze-600 block"
            >Conoce más
          </ScrollLink>
          <button onClick={()=> setOpen(true)} className='w-1/2 sm:w-auto px-4 py-2 vertical-sm:py-2 vertical-sm:px-4 sm:text-lg lg:text-xl lg:px-6 lg:py-3 rounded-md bg-green-haze-600 hover:bg-green-haze-500 transition-colors text-white'>
          Video explicativo
          </button>
        </div>
      </div>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
      <img
        className="absolute -z-10 user-select-none -top-8 h-auto max-h-[70vh] md:max-h-[50vh] w-auto max-w-[60vw] md:max-w-[50vw] lg:max-w-[70vw] md:top-24 right-0"
        alt="Img"
        src={AguacateRama}
      />
    </div>
  )
}

export default Section2Header