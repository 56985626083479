import React from 'react'
// import dashboard from '../../assets/sectionDashboardService/1e5f600e-a71e-4d15-bdfb-8768c8014d56.jpg';
import dashboard from '../../assets/captura-dashboard-inicio.webp';
import { Icon } from '@iconify/react';


const dataAndDash = ({ size }) => {
 

  return (
    // <div id="blockchainSection" className="relative px-5 container transition-[max-width] duration-500 sm:mx-auto sm:px-10"> {/**w-screen */}
    // <div className="grid h-screen w-full grid-cols-12 grid-rows-12 gap-x-0.5">
    <div className="relative grid h-screen container md:top-24 transition-[max-width] duration-500 grid-cols-12 grid-rows-12 md:grid-rows-[repeat(15_,_minmax(0,_1fr))] w-full px-5 sm:px-10 mx-auto md:pt-10 xl:pt-20"> {/**h-screen  */}
      <div className=" col-start-1 md:col-start-4 col-span-12  row-start-2 md:row-start-1 row-span-2 md:col-span-9 ">
        <h3 className="text-left md:text-right">
          Generación de datos durante el proceso y uso de ERP/CRM corporativo
        </h3>
      </div>
      {/* Dashboard Area */}
      <div className="flex w-full h-full justify-end col-start-1 md:col-start-1 col-span-7 md:col-span-3 row-start-6 md:row-start-6 md:row-span-9 row-span-6 z-10">
        <div className='w-fit flex h-full aspect-[144/81] mr-4 md:mr-0'>
          <img
            className="w-auto h-full object-right object-contain rounded-xl shadow-lg"
            alt="Dashboard"
            src={dashboard}
          />
        </div>
      </div>      
        {/* Additional dashboard elements can be added here */}
      <div className='p-1 flex items-center justify-center col-start-3 md:col-start-5 col-span-2 row-start-4 md:row-start-5 md:row-span-2 text-curious-blue-600'>
        <Icon className='w-full md:w-20 lg:w-24 h-full' icon="lucide:package"/>
      </div>
      <div className='p-1 flex items-center justify-center col-start-5 md:col-start-7  col-span-2 row-start-4 md:row-start-5 md:row-span-2 text-curious-blue-600'>
        <Icon className='w-full md:w-20 lg:w-24 h-full' icon="mdi:event-edit"/>
      </div>
      <div className='p-1 flex items-center justify-center col-start-7 md:col-start-9  col-span-2 row-start-4 md:row-start-5 md:row-span-2 text-curious-blue-600'>
        <Icon className='w-full md:w-20 lg:w-24 h-full' icon="fluent:building-48-filled"/>
      </div>
      <div className='p-1 flex items-center justify-center col-start-9 md:col-start-11  col-span-2 row-start-4 md:row-start-5 md:row-span-2 text-curious-blue-600'>
        <Icon className='w-full md:w-20 lg:w-24 h-full' icon="ic:round-factory"/>
      </div>
      {/* Main Content Area */}
      <div className="col-start-3 user-select-none col-span-1 row-start-5 row-span-2 md:col-start-4  md:col-span-2 md:row-start-7 md:row-span-1 md:rounded-br-xl border-r-2 md:border-b-2 border-dashed border-curious-blue-500"></div>
      <div className="col-start-3 user-select-none col-span-3 row-start-5 row-span-3 md:col-start-4 md:col-span-4 md:row-start-7 md:row-span-3 rounded-br-xl border-r-2 md:border-b-2 border-dashed border-curious-blue-500"></div>
      <div className="col-start-3 user-select-none col-span-5 row-start-5 row-span-4 md:col-start-4 md:col-span-6 md:row-start-7 md:row-span-5 rounded-br-xl border-r-2 border-b-2 border-dashed border-curious-blue-500"></div>
      <div className="col-start-3 user-select-none col-span-7 row-start-5 row-span-5 md:col-start-4 md:col-span-8 md:row-start-7 md:row-span-7 rounded-br-xl border-r-2 border-b-2 border-dashed border-curious-blue-500"></div>
      <div className="col-start-2 user-select-none col-span-1 row-span-1 row-start-12 border-l-2 border-dashed border-curious-blue-500" ></div>

    </div>
  )
}


export default dataAndDash