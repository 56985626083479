import React, { useState, useEffect } from "react";
// import { ReactComponent as BlockchainDesktop } from "./Animation-blockchain_desktop.svg";
// import { ReactComponent as BlockchainMobile } from "./Animation-blockchain_mobile.svg";

import "./BlockchainImage.css"; // Import CSS file for animations

const BlockchainImage = ({ isInView }) => {
  const [animationStarted, setAnimationStarted] = useState(false);
  useEffect(() => {
    if (isInView && !animationStarted) {
      setAnimationStarted(true);
    }
  }, [isInView, animationStarted]);

  const svgStyle = {
    fillRule: "evenodd",
    clipRule: "evenodd",
    strokeLinecap: "round",
    strokeMiterlimit: 10,
  };

  const pathStyle = {
    strokeWidth: "4.17px",
    strokeLinejoin: "round",
    strokeMiterlimit: "1.5",
    // strokeDashoffset: "1000",
    // strokeDashArray: "1000",
  };

  const rectStyle = {
    fill: "none",
    stroke: "#14ace3",
    strokeWidth: "4.17px",
  };

  const textStyle = {
    fontFamily: "'OpenSans-Regular', 'Open Sans', sans-serif",
  };

  return (
    <div className="BlockchainImage max-h-[28rem] vertical-sm:max-h-80 h-full w-full z-10">
      {/* <BlockchainDesktop className={`max-w-xl my-auto mx-auto hidden md:block duration-300 h-full w-full text-green-haze-950 + ${isInView ? '' : 'scale-50'}`} /> */}
      {/* <BlockchainMobile className="md:hidden mx-auto h-full w-full text-green-haze-950" /> */}

      <svg
        width="100%"
        height="100%"
        viewBox="0 0 651 651"
        version="1.1"
        id="blockchain-animation"
        className={` hidden md:block h-full w-full max-w-xl my-auto mx-auto text-green-haze-950 ${
          animationStarted ? "animation-started " : ""
        }`}
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}>
        <clipPath id="_clip1">
          <rect x="0.579" y="0.569" width="649.98" height="649.98" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g id="blockchain">
            <rect
              id="top-right"
              x="403.121"
              y="75.227"
              width="29.756"
              height="29.756"
              style={rectStyle}
            />
            <path
              d="M363.921,258.238c-0,0 44.911,-25.929 52.877,-30.528c0.743,-0.429 1.201,-1.222 1.201,-2.08c0,-13.475 0,-120.647 0,-120.647"
              style={pathStyle}
            />
            <rect
              id="bottom-right"
              x="403.121"
              y="446.2"
              width="29.756"
              height="29.756"
              style={rectStyle}
            />
            <path
              d="M363.921,391.094c-0,0 44.911,25.93 52.877,30.529c0.743,0.429 1.201,1.222 1.201,2.08l0,22.497"
              style={pathStyle}
            />
            <rect
              id="bottom-left"
              x="42.086"
              y="508.845"
              width="29.756"
              height="29.756"
              style={rectStyle}
            />
            <path
              d="M287.217,391.094c-0,0 -128.165,73.996 -137.679,79.489c-0.365,0.211 -0.779,0.322 -1.201,0.322c-7.34,-0 -75.272,-0 -88.972,-0c-0.637,-0 -1.248,0.253 -1.698,0.703c-0.45,0.45 -0.703,1.061 -0.703,1.698c-0,8.339 -0,35.539 -0,35.539"
              style={pathStyle}
            />
            <rect
              id="bottom"
              x="310.707"
              y="574.272"
              width="29.756"
              height="29.756"
              style={rectStyle}
            />
            <path d="M325.585,413.227l0,161.045" style={pathStyle} />
            <rect
              id="top-left"
              x="54.23"
              y="19.262"
              width="29.756"
              height="29.756"
              style={rectStyle}
            />
            <path
              d="M287.217,258.238c-0,0 -199.559,-115.215 -216.908,-125.231c-0.743,-0.429 -1.201,-1.222 -1.201,-2.08c-0,-10.864 -0,-81.909 -0,-81.909"
              style={pathStyle}
            />
            <path
              id="cube"
              d="M325.569,324.666c-0,0 -0,76.298 -0,87.264c-0,0.27 -0.144,0.519 -0.377,0.654c-0.234,0.134 -0.521,0.134 -0.755,-0c-9.077,-5.241 -69.838,-40.322 -75.195,-43.415c-0.234,-0.134 -0.378,-0.383 -0.378,-0.653c0,-6.186 0,-76.347 0,-86.828c0,-0.27 0.144,-0.519 0.378,-0.654c0.233,-0.134 0.521,-0.134 0.754,0c9.077,5.241 69.838,40.322 75.196,43.415c0.233,0.134 0.377,0.383 0.377,0.653c-0,6.186 -0,76.347 -0,86.828c-0,0.27 0.144,0.519 0.377,0.654c0.233,0.134 0.521,0.134 0.754,-0c9.077,-5.241 69.839,-40.322 75.196,-43.415c0.233,-0.134 0.377,-0.383 0.377,-0.653c0,-6.186 0,-76.347 0,-86.828c0,-0.27 -0.144,-0.519 -0.377,-0.654c-0.234,-0.134 -0.521,-0.134 -0.754,0c-9.498,5.484 -75.573,43.632 -75.573,43.632l75.573,-43.632c0.233,-0.134 0.377,-0.383 0.377,-0.653c-0,-0.269 -0.144,-0.518 -0.377,-0.653c-9.078,-5.241 -69.839,-40.321 -75.196,-43.414c-0.233,-0.135 -0.521,-0.135 -0.754,-0c-5.358,3.093 -66.119,38.173 -75.196,43.414c-0.233,0.135 -0.377,0.384 -0.377,0.653c-0,0.27 0.144,0.519 0.377,0.653c9.077,5.241 69.838,40.322 75.196,43.415c0.233,0.134 0.521,0.134 0.754,-0c5.791,-3.344 76.327,-44.068 76.327,-44.068"
              style={{ ...pathStyle, fill: "rgb(229 239 213)}" }}
            />
          </g>
          <g transform="matrix(29.1667,0,0,29.1667,620.07,601.361)" />
          <text x="354.835px" y="601.361px" style={textStyle}>
            Integridad de datos
          </text>
          <g transform="matrix(1,0,0,1,-63.5168,370.385)">
            <text x="149.025px" y="160.62px" style={textStyle}>
              Interfaz Web
            </text>
            <g transform="matrix(29.1667,0,0,29.1667,276.102,198.536)" />
            <text x="149.025px" y="198.536px" style={textStyle}>
              Dedicada
            </text>
          </g>
          <g transform="matrix(29.1667,0,0,29.1667,648.138,101.272)" />
          <text x="447.603px" y="101.272px" style={textStyle}>
            Cifrado seguro
          </text>
          <g transform="matrix(1,0,0,1,447.603,446.494)">
            <text x="0px" y="22.16px" style={textStyle}>
              Seguridad en
            </text>
            <g transform="matrix(29.1667,0,0,29.1667,184.698,60.0765)" />
            <text x="0px" y="60.076px" style={textStyle}>
              la Producción
            </text>
          </g>
          <g transform="matrix(1,0,0,1,95.3249,19.035)">
            <text x="0px" y="22.16px" style={textStyle}>
              Almacenamiento
            </text>
            <g transform="matrix(29.1667,0,0,29.1667,218.223,60.0765)" />
            <text x="0px" y="60.076px" style={textStyle}>
              Descentralizado
            </text>
          </g>
        </g>
      </svg>

      <svg
        width="100%"
        height="100%"
        viewBox="0 0 650 651"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}
        id="blockchain-animation"
        className={` md:hidden block mx-auto h-full w-full ${
          animationStarted ? "animation-started " : ""
        }`}>
        <g id="blockchain">
          <rect
            id="top-right"
            x="402.552"
            y="127.437"
            width="29.756"
            height="29.756"
            style={rectStyle}
          />
          <path
            d="M363.352,258.944c0,0 43.816,-25.297 52.568,-30.35c0.935,-0.54 1.51,-1.537 1.51,-2.616c0,-10.862 0,-68.785 0,-68.785"
            style={pathStyle}
          />
          <rect
            id="bottom-right"
            x="455.37"
            y="408.144"
            width="29.756"
            height="29.756"
            style={rectStyle}
          />
          <path
            d="M363.352,391.8c0,-0 47.049,27.163 53.377,30.817c0.46,0.265 0.98,0.405 1.511,0.405c5.497,-0 37.13,-0 37.13,-0"
            style={pathStyle}
          />
          <rect
            id="bottom-left"
            x="28.968"
            y="483.726"
            width="29.756"
            height="29.756"
            style={rectStyle}
          />
          <path
            d="M286.648,391.8c-0,-0 -84.238,48.634 -92.806,53.581c-0.459,0.265 -0.98,0.405 -1.51,0.405c-10.751,-0 -125.307,-0 -145.466,-0c-0.801,-0 -1.569,0.318 -2.136,0.885c-0.566,0.566 -0.884,1.334 -0.884,2.136l-0,34.919"
            style={pathStyle}
          />
          <rect
            id="bottom"
            x="310.138"
            y="568.089"
            width="29.756"
            height="29.756"
            style={rectStyle}
          />
          <path d="M325.016,413.933l0,154.156" style={pathStyle} />
          <rect
            id="top-left"
            x="102.393"
            y="44.728"
            width="29.756"
            height="29.756"
            style={rectStyle}
          />
          <path
            d="M286.648,258.944c-0,0 -154.136,-133.336 -168.332,-145.617c-0.663,-0.573 -1.045,-1.407 -1.045,-2.284c0,-6.818 0,-36.559 0,-36.559"
            style={pathStyle}
          />
          <path
            id="cube"
            d="M325,325.372l0,86.072c0,0.515 -0.275,0.991 -0.721,1.249c-0.447,0.258 -0.997,0.258 -1.443,0c-11.673,-6.739 -66.862,-38.603 -73.819,-42.619c-0.446,-0.258 -0.721,-0.734 -0.721,-1.25l-0,-85.238c-0,-0.516 0.275,-0.992 0.721,-1.25c0.446,-0.258 0.996,-0.258 1.443,0c12.464,7.196 74.54,43.036 74.54,43.036l0,86.072c-0,0.515 0.275,0.991 0.721,1.249c0.447,0.258 0.997,0.258 1.443,0c11.673,-6.739 66.862,-38.603 73.819,-42.619c0.446,-0.258 0.721,-0.734 0.721,-1.25l0,-85.238c0,-0.516 -0.275,-0.992 -0.721,-1.25c-0.446,-0.258 -0.996,-0.258 -1.443,0c-12.464,7.196 -74.54,43.036 -74.54,43.036l74.54,-43.036c0.447,-0.258 0.722,-0.734 0.722,-1.249c-0,-0.516 -0.275,-0.992 -0.722,-1.25c-11.673,-6.739 -66.862,-38.602 -73.819,-42.619c-0.446,-0.258 -0.996,-0.258 -1.442,-0c-6.957,4.017 -62.146,35.88 -73.819,42.619c-0.447,0.258 -0.722,0.734 -0.722,1.25c0,0.515 0.275,0.991 0.722,1.249c12.464,7.196 74.54,43.036 74.54,43.036l76.704,-44.285"
            style={{ ...pathStyle, fill: "rgb(229 239 213)}" }}
          />
        </g>
        <g transform="matrix(20.8333,0,0,20.8333,634.063,148.387)" />
        <text x="444.61px" y="148.387px" style={textStyle}>
          Integridad de datos
        </text>
        <g transform="matrix(20.8333,0,0,20.8333,289.961,506.994)" />
        <text x="70.092px" y="506.994px" style={textStyle}>
          Interfaz Web Dedicada
        </text>
        <g transform="matrix(20.8333,0,0,20.8333,639.063,430.936)" />
        <text x="495.823px" y="430.936px" style={textStyle}>
          Cifrado seguro
        </text>
        <g transform="matrix(20.8333,0,0,20.8333,616.766,590.1)" />
        <text x="351.324px" y="590.1px" style={textStyle}>
          Seguridad en la Producción
        </text>
        <g transform="matrix(20.8333,0,0,20.8333,471.099,65.3173)" />
        <text x="145.354px" y="65.317px" style={textStyle}>
          Almacenamiento Descentralizado
        </text>
      </svg>
    </div>
  );
};
export default BlockchainImage;
