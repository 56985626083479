import React, { useState, useEffect } from 'react';
import MapLocation from '../charts/MapLocation';
import { useStateContext } from '../../contexts/ContextProvider';


const Compare = () => {
  const {locations, setLocations} = useStateContext();
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  
  const fetchData = async (category) => {
    try {
      // Call the serverless function here
      const response = await fetch(`/api/getTrustyData?category=${category}`, {
        method: 'GET'
      });
      const retrievedJSON = await response.json();
      
      if (response.ok && Array.isArray(retrievedJSON.data)) {
        const formattedData = retrievedJSON.data.map(item => ({
          ...item,
        }));

        const categoryLocations = formattedData.map(item => ({
          name: item.name,
          lat: item.lat,
          lng: item.lng,
          type: item.type,
          category: category,
        }));

        setLocations(prevLocations => [...prevLocations, ...categoryLocations]);
      } else {
        setError('Error en el formato de datos o en la respuesta del servidor');
      }
      
    } catch (error) {
      setResponseMessage('Error conectando con el servidor');
    }
  };
  console.log('locations', locations);
  useEffect(() => {
    fetchData('companies');
    // fetchData('facilities');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="analisis" className="flex container w-full transition-[max-width] px-5 md:px-10 duration-500 mx-auto items-center min-h-screen justify-center relative">
      <div className="w-full  md:space-x-8 space-y-8 md:space-y-0 flex flex-col md:flex-row h-auto items-center ">
        <div className={`flex flex-col  md:px-0 space-y-4 items-start justify-center w-full ${locations.length > 0 ? 'md:w-1/2' : 'md:w-full'} `}>
          <h3 className="text-left">
            Localiza nuestra red de actores
            {/* Analiza todos los competidores con total confianza */}
          </h3>
          <p>
            En el mapa puedes ver la ubicación de todos las actores que están en la plataforma. La red de actores incluye empresas e instalaciones.
          </p>
          {/* Display error message if error */}
          {error && <p>Error: {error}</p>}
          {/* Display response message if any */}
          {responseMessage && <p>{responseMessage}</p>}
        </div>
          {locations  &&
            <div className="w-full z-0 md:w-1/2 min-h-max h-full md:flex-col items-start  md:items-center justify-center overflow-x-hidden relative ">
              <div className="h-[40vh] max-w-4xl w-full overflow-clip rounded-md shrink-1 grow-1 map-container">
                  <MapLocation locations={locations} height="100%" width="100%"/>
                </div>
            </div>
            }
      </div>
    </div>
  );
};

export default Compare;