import React from 'react'
import imgQR from '../../assets/sectionConsumer/codigo-qr.svg';

const ConsumerSection = () => {
  return (
    <div id="consumidor" className="relative duration-500 grid container transition-[max-width] h-screen w-full grid-cols-12 grid-rows-12 sm:mx-auto px-5 sm:px-10">
        <div className="select-none pointer-events-none col-span-5 col-start-2 row-span-9 md:row-span-8 !row-start-1 -my-px rounded-bl-xl border-b-2 border-l-2 border-dashed border-curious-blue-500">        
        </div>        
        <div className="select-none pointer-events-none col-span-6 col-start-7 row-span-3 md:row-span-4 row-start-10 md:row-start-9 -my-px rotate-180 rounded-bl-xl border-b-2 border-l-2 border-dashed border-curious-blue-500">
        </div>
        <div className="col-start-3 flex flex-col space-y-3 row-start-3 col-span-9 ">
          <h3 className="text-left">
            Creamos pasaportes digitales
          </h3>
          <p>
            A través de una interfaz web personalizada, los consumidores pueden escanear el código QR presente en la etiqueta del producto para acceder a información detallada acerca de la cadena de suministro.
          </p>
        </div>
        <div className="col-span-9 col-start-3 row-span-4 row-start-8 md:row-start-7 relative flex justify-center items-center -my-px">
          <div className='w-full h-full flex items-center justify-center'>
            <div className="w-full max-w-xl max-h-72 h-fit md:h-full py-4 px-4 shadow-md bg-white/50 rounded-lg backdrop-blur-md justify-center items-center gap-4 flex">
              <div className="aspect-square  w-full md:w-fit h-full flex justify-center items-center">
                <img
                  className="w-full h-full object-contain"
                  alt="QR code"
                  src={imgQR}
                  />
              </div>
              <div className="w-full h-full flex-col  justify-center space-y-1 md:space-y-2 items-start flex">
                <div className="text-left text-curious-blue-600 text-sm md:text-xl mb-auto font-bold font-serif">Aguacate Hass</div>
                <p className="text-curious-blue-600 text-[0.6rem] sm:text-xs font-bold font-sans">LOT:</p>
                <p className="text-curious-blue-600 text-[0.6rem] sm:text-xs font-normal font-sans">1283171240123777T</p>
                <p className="text-curious-blue-600 text-[0.6rem] sm:text-xs font-bold font-sans">GTIN:</p>
                <p className="text-curious-blue-600 text-[0.6rem] sm:text-xs font-normal font-sans">17238 1723-12</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ConsumerSection