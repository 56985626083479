import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
// import { useScroll } from 'react-spring';

const BlogSection = () => {
  // const STRAPI_API_TOKEN = process.env.STRAPI_API_TOKEN;

  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [dataFetched, setDataFetched] = useState(false);

  /**
     * axios.get('https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods', {
            headers: {
                'Authorization': `Bearer ${STRAPI_API_TOKEN}`
            }
        })
     */
  /**
     * useEffect(() => {
      axios.get('https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods?populate[autor]=*&populate[imagens]=*')
            .then(response => {
                console.log("response data data",response.data.data);
                //console.log("response data typeof", typeof response.data.data, response.data.data);
                setPosts(Object.values(response.data.data));
                console.log("posts: ", posts);
                console.log("post.attributes.imagens.data",posts.attributes.imagens.data);
                //console.log("posts typeof", typeof posts, posts);
            })
            .catch(error => {
                console.error("Error al cargar los posts:", error);
            });
    }, []);
     */
  // const BASE_URL = 'https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods?populate=*';
  const IMAGE_URL =
    "https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com";
  //axios.get('https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods?populate[autor]=*&populate[imagens]=*')
  useEffect(() => {
    axios
      .get(
        "https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods?populate=*"
      )
      //https://caaskmeuropa-landing-blog-214484eae7ab.herokuapp.com/api/zero-foods?populate=*
      .then((response) => {
        // console.log("response data data",response.data.data);
        //console.log("response data typeof", typeof response.data.data, response.data.data);
        setPosts(Object.values(response.data.data));
        // console.log("posts: ", posts);
        //console.log("post.attributes.imagens.data",posts.attributes.imagens.data);
        //console.log("posts typeof", typeof posts, posts);
      })
      .catch((error) => {
        console.error("Error al cargar los posts:", error);
      });
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
    setIsPopupOpen(true);
  };

  const handleClosePost = () => {
    setIsPopupOpen(false);
  };

  const handleKeyPress = (event, post) => {
    if (event.key === "Enter" || event.key === "") {
      handlePostClick(post);
    }
  };
  console.log("posts: ", posts);

  /**
     * <div className="flex flex-col sm:flex-row gap-4">
      {posts.map(post => (
        <div key={post.id} className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold">{post.attributes.Name}</h2>
          <div>
            {post.attributes.Description.map((paragraph, index) => (
              <p key={index} className="mt-2 text-gray-600">{paragraph.children.map(child => child.text).join('')}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
     */

  // console.log('selectedPost', selectedPost);
  return (
    <div
      id="blog"
      className="container mx-auto transition-[max-width] py-10 px-5 md:px-10 duration-500">
      <h1 className="text-3xl font-bold text-center my-8 text-curious-blue-600">
        Blog
      </h1>
      <div className="flex flex-col items-stretch sm:flex-row gap-4">
        {posts.map((post) => (
          <div
            key={post.id}
            className="w-full flex flex-col rounded-lg overflow-clip shadow-md bg-white">
            {/*Render image if it exists */}
            {/* {post.attributes.Image?.data?.length > 0 &&
              post.attributes.Image.data.map((image, index) => (
                <img
                  key={index}
                  src={`${IMAGE_URL}${image.attributes.formats.small.url}`}
                  alt={
                    image.attributes.alternativeText ||
                    `Post image ${index + 1}`
                  }
                  className="mb-3"
                />
              ))} */}
            <div className="flex flex-col h-full p-4">
              <h2 className="text-left text-xl text-curious-blue-600 font-bold">
                {post.attributes.Name}
              </h2>
              <p className="text-sm">
                Autor: {post.attributes.autor.data.attributes.autor}
              </p>
              <div className="line-clamp-6  md:line-clamp-8">
                {post.attributes.Description.map((paragraph, index) => (
                  <p key={index} className=" mt-2 text-gray-600">
                    {paragraph.children.map((child) => child.text).join("")}
                  </p>
                ))}
              </div>
              <button
                onClick={() => handlePostClick(post)}
                onKeyDown={(e) => handleKeyPress(e, post)}
                className="ml-auto mt-auto text-sm text-curious-blue-600 hover:text-curious-blue-500">
                leer más
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* if visible */}
      {isPopupOpen && selectedPost && (
        <div className="fixed inset-0 pt-20  flex z-10 ">
          <button
            className="cursor-default absolute inset-0 bg-black/30 backdrop-blur-sm"
            onClick={handleClosePost}></button>
          <div className="relative !rounded-t-lg overflow-clip container mt-auto mx-auto bg-white shadow-xl w-full h-3/4 md:h-1/2">
            <div className="flex flex-col md:flex-row gap-y-4  h-full overflow-x-hidden overflow-y-scroll">
              {/* {selectedPost.attributes.Image?.data?.length > 0 &&
                selectedPost.attributes.Image.data.map((image, index) => (
                  <img
                    key={index}
                    src={`${IMAGE_URL}${image.attributes.formats.small.url}`}
                    alt={
                      image.attributes.alternativeText ||
                      `Post image ${index + 1}`
                    }
                    className="md:h-full md:w-1/2 object-cover md:sticky md:top-0"
                  />
                ))} */}

              <div className="p-4 md:p-8">
                <h2 className="text-left text-xl text-curious-blue-600 font-bold">
                  {selectedPost.attributes.Name}
                </h2>
                <p className="text-sm">
                  Autor: {selectedPost.attributes.autor.data.attributes.autor}
                </p>
                <div>
                  {selectedPost.attributes.Description.map(
                    (paragraph, index) => (
                      <p
                        key={index}
                        className="text-justify hyphens-auto mt-2 text-gray-600">
                        {paragraph.children.map((child) => child.text).join("")}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="absolute z-20 h-auto container w-full left-1/2 -translate-x-1/2 flex justify-end top-3 pr-4">
              <button
                className={
                  "w-6 h-6 " + selectedPost.attributes.Image?.data !== null
                    ? "text-curious-blue-500"
                    : "text-curious-blue-500"
                }
                onClick={handleClosePost}>
                <Icon icon="material-symbols:close" className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogSection;
