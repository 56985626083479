import React,  { useState, useEffect } from 'react'
import imgScreenshotQR from '../../assets/sectionProduct/Screenshot-qrcode.png';
import imgScreenshotTrack from '../../assets/sectionProduct/Screenshot-track.png';
import imgAvocado from '../../assets/sectionProduct/Avocado-End.png';

const ProductSection = () => {
  return (
  <div className="relative h-screen container transition-[max-width] duration-500 sm:mx-auto px-5 sm:px-10">
    <div className="grid h-full w-full grid-cols-12 grid-rows-12">
      <div className="col-span-6 col-start-7 row-span-6 row-start-1 md:col-start-3 md:row-start-1 md:col-span-10 md:row-span-8 -my-px rounded-br-xl border-b-2 border-r-2 border-dashed border-curious-blue-500"></div>
      <div className="md:hidden col-start-1 row-start-7 col-span-6 row-span-5 -my-px rounded-l-xl border-y-2 border-l-2 border-dashed border-curious-blue-500"></div>
      {/* <div className="md:hidden col-start-4 row-start-1 col-span-1 row-span-2 -my-px rounded-br-xl border-r-2 border-dashed border-curious-blue-500"></div> */}
      <div className="col-start-1 col-span-12 p-2 text-left flex flex-col space-y-4 row-start-1 md:row-start-3">
        <h3>
          Escanea el código QR para estar al corriente
        </h3>
        <p className='max-w-[80ch]'>
          Mediante el desarrollo de una interfaz web específica, el consumidor puede escanear el código QR de la etiqueta del producto y obtener toda la información de la cadena de suministro
        </p>
      </div>
      <div className="col-start-6 row-start-10 col-span-5 row-span-4 -translate-y-[0%] md:col-start-1 md:row-start-6 md:row-span-4 md:col-span-3  md:-translate-y-[0%]"> 
          <img
            className="object-contain h-full w-full" 
            alt="Dashboard"
            src={imgAvocado}
          />
      </div>
      <div className="col-start-4 row-start-5 col-span-3 translate-y-[8%] row-span-3 md:col-start-5 md:col-span-4 md:row-start-5 md:row-span-5 md:translate-y-[21%]"> 
          <img
            className="object-contain h-full w-full " 
            alt="Dashboard"
            src={imgScreenshotTrack}
          />
      </div>
      <div className="col-start-8 row-start-6 col-span-3 translate-y-[3%] row-span-3 md:col-start-9 md:col-span-4 md:row-start-7 md:row-span-5 md:translate-y-[10%]"> 
          <img
            className="object-contain h-full w-full" 
            alt="Dashboard"
            src={imgScreenshotQR}
          />
      </div>
    </div>
  </div>
  )
}

export default ProductSection