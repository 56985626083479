import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as ScrollLink } from 'react-scroll';
import imgZeroFood from '../../assets/navbar/ZeroFood-logo.webp';

const NavbarSection = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={"z-40 text-green-haze-700 fixed inset-x-0 md:backdrop-blur-sm md:bg-lime-zero-100/50 md:hover:bg-lime-zero-100 transition-colors" + (isMobileMenuOpen ? ' bg-lime-zero-100' : '')}>
      {isMobileMenuOpen && (
        <button onClick={() => setIsMobileMenuOpen(false)} className='bg-black/20 cursor-default md:hidden absolute w-screen h-screen inset-0 z-10'>

        </button>
      )}
      <div className={"relative flex justify-between p-4 z-30 items-center" + (isMobileMenuOpen ? ' bg-lime-zero-100' : '')}>
      <ScrollLink 
              key="inicio"
              to="inicio"
              smooth={true} 
              duration={500} 
              className="cursor-pointer"
            >
            <img className="h-14" alt="Logo" src={imgZeroFood} />
          </ScrollLink>

        {/* Burger Icon for Mobile*/}
          <button onClick={toggleMobileMenu} className={"p-2 rounded-md z-30 bg-lime-zero-100 md:hidden" + (isMobileMenuOpen ? '' : ' shadow-md')}>
            <Icon icon="iconamoon:menu-burger-horizontal-light" className="text-4xl text-curious-blue-700" />
          </button>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-8">
        {['Inicio', 'Caso de uso',  'Dashboard', 'Blockchain', 'Consumidor', 'Feedback', 'Blog'].map((item) => (
            <ScrollLink 
              key={item}
              to={item.toLowerCase()} 
              smooth={true} 
              duration={500} 
              className="font-sans text-sm hover:text-green-haze-500 transition-color duration-200 whitespace-nowrap font-bold cursor-pointer"
            >
              {item}
            </ScrollLink>
          ))}          
          <a href="https://dashboard.zerofood.eu" className="flex items-center justify-center bg-curious-blue-600 hover:bg-curious-blue-500 text-sm text-lime-zero-100 transition-color duration-200 font-bold py-2 pl-3 px-4 rounded mx-2 "> 
            <Icon icon="material-symbols-light:lock" className='w-4 h-4 mr-2'></Icon>
            Acceso
          </a>
        </div>
      
      </div>
      
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <ul className="md:hidden absolute inset-x-0 top-[100%] z-20 bg-lime-zero-100 shadow-md w-full">
          {['Inicio', 'Caso de uso', 'Blockchain', 'Dashboard', 'Consumidor', 'Feedback', 'Blog'].map((item) => (
          <li key={item} className="text-center p-2 text-base mx-4 hover:text-green-haze-500 whitespace-nowrap font-bold">
            <ScrollLink 
              to={item.toLowerCase()} 
              smooth={true} 
              duration={500} 
              onClick={() => setIsMobileMenuOpen(false)}
              className="cursor-pointer"
            >
              {item}
            </ScrollLink>
          </li>
        ))}
          <li className="text-center p-2">
          <a href="https://dashboard.zerofood.eu" className="flex items-center justify-center bg-curious-blue-600 hover:bg-curious-blue-500 text-sm text-lime-zero-100 transition-color duration-200 font-bold py-2 pl-3 px-4 rounded mx-2 "> 
              <Icon icon="material-symbols-light:lock" className='w-4 h-4 mr-2'></Icon>
              Acceso
            </a>
          </li>
        </ul>
      )}      
    </nav>
  )
}

export default NavbarSection