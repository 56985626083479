import React, {createContext, useContext, useState, useEffect} from 'react';
import fetchTrusty from '../helpers/fetchTrusty';

const StateContext = createContext();

/**We define the initial state of the chat, cart and notification in the navbar at the top. If they are opened or closed. */
// const initialState = {
//     help: false,
//     settings: false,
//     userProfile: false,
//     notification: false,
// }

export const ContextProvider = ({children}) => {
    // const [activeMenu, setActiveMenu] = useState (true);
    // const [isClicked, setIsClicked] = useState (initialState);
    /**on click we take the initial state (everything's false) and we change only the value of the object we clicked */
    // const handleClick = (clicked) => {setIsClicked({ ...initialState, [clicked]:true})};
    /**set screen size for handling small size screens */
    // const [screenSize, setScreenSize] = useState (undefined);
    // const [activeAppbar, setActiveAppbar] = useState (true);
    // const [activeLoginForm, setActiveLoginForm] = useState (false);
    // const [showIconsOnly, setShowIconsOnly] = useState(false);
    //
    const [companies, setCompanies] = useState([]); //Share data from Instalations (Transports) menu and events
    const [locations, setLocations] = useState([]); //Share data from Instalations (Transports) menu and events
    const [traceLocations, setTraceLocations] = useState([]); 
    // const [products, setProducts] = useState([]); //Share data from Productos menu and events
    // const [events, setEvents] = useState([]); 
    //
    const [companyCount, setCompanyCount] = useState(0);
    const [facilityCount, setFacilityCount] = useState(0);
    // const [productCount, setProductCount] = useState(0);
    // const [eventsCount, setEventsCount] = useState(0);
    //
    //const [refreshData, setRefreshData] = useState(false);
    // State variables for user profile
    // const [userProfile, setUserProfile] = useState({
    //     name: '',
    //     email: '',
    //     profession: ''
    // });
    //
    // const [CO2Transport, setCO2Transport] = useState(null);
    // const [CO2Packaging, setCO2Packaging] = useState(null);
    // const [CO2TransportMx, setCO2TransportMx] = useState(null);
    // const [CO2PackagingMx, setCO2PackagingMx] = useState(null);

    //Fetch data from instalaciones and productos. It'll be used in eventos to show the names
    //of locations and products instead of the GTIN number.
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching data for locations and products
                const companiesData = await fetchTrusty('companies');
                // const traceLocationsData = await fetchTrusty('traceFacilities');
                const locationsData = await fetchTrusty('facilities');
                // const productsData = await fetchTrusty('products');
                // const eventsData = await fetchTrusty('events');

                //The data from fethTrusty is returned as an object, containing the status and the data.
                //Here he check that's not empty and we set only the data
                // Check if the response has data and is valid
                if (companiesData.status && companiesData.data) {
                    setCompanies(companiesData.data);
                    setCompanyCount(companiesData.data.length);
                }
                // console.log("ContextProvider companiesData", companiesData);

                // if (traceLocationsData.status && traceLocationsData.data) {
                //     setTraceLocations(traceLocationsData.data);
                // }
                // console.log("ContextProvider traceLocationsData", traceLocationsData);

                if (locationsData.status && locationsData.data) {
                    setLocations(locationsData.data);
                    setFacilityCount(locationsData.data.length);
                }
                // console.log("ContextProvider locationsData", locationsData);

                // if (productsData.status && productsData.data) {
                //     setProducts(productsData.data);
                //     setProductCount(productsData.data.length);
                // }
                // if (eventsData.status && eventsData.data) {
                //     setEvents(eventsData.data);
                //     setEventsCount(eventsData.data.length);
                // }
                // console.log("ContextProvider productsData", productsData);
                // console.log("ContextProvider eventsData", eventsData);

                // Update the states
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);



    return (
        <StateContext.Provider 
            value={{
                companies,
                setCompanies,
                // traceLocations,
                // setTraceLocations,
                locations,
                setLocations,
                companyCount,
                facilityCount,

            }}>                                   
            {children}
        </StateContext.Provider>
    )
}

/**We use the activeMenu inside the components of the app by exporting the useStateContext. 
 * It's a function that returns the calls to the useContext but we pass which context will we wanna use.
 * Nemo tip: "Give me the data from the context (useStateContext) by using the context (useContext) and we specify which one (StateContext)"
 * In this app we'll have one context but in bigger applications we can have multiple context providers.
*/
export const useStateContext = () => useContext (StateContext);