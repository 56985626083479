import React from "react";
import { Icon } from "@iconify/react";
import PoweredByTrusty from "../../assets/sectionFooter/trusty-logo.svg";
import LogoEu from "./logo-eu.webp";
import LogoGob from "./logo-gob.webp";
import LogoGeneralitatValenciana from "./logo-generalitat-valenciana.svg";
import LogoRPTR from "./logo-rptr.webp";
import LogoIVACE from "./logo-ivace.webp";
const FooterSection = () => {
  return (
    <footer className="bg-curious-blue-600 p-0 md:p-4 mt-8">
      <div className="container mx-auto py-4 px-5 flex flex-col items-center">
        {/* Fila superior */}
        <div className="w-full flex flex-col sm:flex-col  justify-between items-start space-y-2 md:space-y-4 mb-4">
          <p class="text-sm text-white">Financiado por:</p>
          <div class="flex justify-start flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
            <img src={LogoEu} alt="Logo de la Unión Europea" className="h-16 object-contain shrink-0 sm:shrink object-left" />
            <img
              src={LogoGob}
              alt="Logo de la Unión Europea"
              className="h-16 object-contain shrink-0 sm:shrink object-left"
            />
            <img
              src={LogoRPTR}
              alt="Logo de la Unión Europea"
              className="h-16 object-contain shrink-0 sm:shrink object-left"
            />
          </div>
          <p className="text-sm text-white">
            Esta convocatoria de ayudas esta contemplada dentro del Programa de
            Capacidades para el Crecimiento de la PYME del Componente 13 del
            Plan de Recuperación, Transformación y Resiliencia.
          </p>
          <p className="text-sm text-white">En colaboración con:</p>
          <div className="flex justify-start flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
            <img
              src={LogoGeneralitatValenciana}
              alt="Logo de la Unión Europea"
              className="h-16 object-contain shrink-0 sm:shrink object-left"
            />
            <img
              src={LogoIVACE}
              alt="Logo de la Unión Europea"
              className="h-16 object-contain shrink-0 sm:shrink object-left"
            />
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-between items-start mb-4">
          {/* Izquierda */}
          <div className="flex gap-y-2 flex-col text-left mb-4 sm:mb-0 w-full">
            <h4>Sitemap</h4>
            <a
              href="/politica-privacidad"
              className="text-sm text-white hover:text-gray-800">
              Política de Privacidad
            </a>
            <a
              href="/terminos"
              className="text-sm text-white hover:text-gray-800">
              Términos de Uso
            </a>
            <a href="/" className="text-sm text-white hover:text-gray-800">
              Página principal
            </a>
          </div>

          {/* Derecha */}
          <div className="flex flex-col text-left w-full gap-y-2">
            <h4>Contacto</h4>
            <div className=" mb-2">
              <p className="text-white text-sm">
                Teléfono:{" "}
                <a
                  className="hover:text-curious-blue-950"
                  href="tel:+34616903537">
                  +34 616 903 537
                </a>
              </p>
              <p className="text-white text-sm">
                Email:{" "}
                <a
                  className="hover:text-curious-blue-950"
                  href="mailto:info@zerofood.eu">
                  hola@guacaguaca.com
                </a>
              </p>
            </div>
            <h4>Síguenos en redes sociales:</h4>
            <div className="inline-flex text-lg justify-start">
              <a href="https://www.facebook.com" className="text-white">
                <Icon icon="ri:facebook-fill" />
              </a>
              <a href="https://www.instagram.com" className="ml-3 text-white">
                <Icon icon="ri:instagram-line" />
              </a>
              <a href="https://www.twitter.com" className="ml-3 text-white">
                <Icon icon="ri:twitter-line" />
              </a>
            </div>
          </div>
          <div className="w-full flex flex-col">
            <p className="text-white text-sm">Powered by</p>
            <img
              className="h-auto w-full max-w-[200px] pointer-events-none user-select-none"
              src={PoweredByTrusty}
              alt="Powered by trusty"
            />
            <p className="text-white text-sm">Developed by Baukunst</p>
          </div>
        </div>

        {/* Fila inferior: Derechos de Autor */}
        <div className="w-full text-center">
          <p className="text-sm text-center text-white">
            © 2023 ZeroFood. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
