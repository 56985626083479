import React, { useState, useEffect } from 'react';
import ChartBar from '../charts/ChartBar';
import ChartBarCO2 from '../charts/ChartBarCO2';
import ChartLine from '../charts/ChartLine';
import PieChart from '../charts/PieChart';
import JsonCO2import from '../../data/chartBarCO2.json'; //didn't work

const LeftPanel = () => {
    const [activeChart, setActiveChart] = useState('co2');
    const [infoText, setInfoText] = useState("El consumo de producto local y de temporada permite tener una menor emisión de CO2 en la producción y transporte."); // Default text for CO2
    const jsonCO2 = '../../data/chartBarCO2.json'; //worked
    const jsonCO2public = '/data/chartBarCO2.json' //worked
    const jsonWater = '/data/chartLineWater.json'; //worked
    const [autoSwitch, setAutoSwitch] = useState(true);
    const [progress, setProgress] = useState(0); // Progress state

    //<button className="bg-lime-zero-100 text-curious-blue-600 px-4 py-2 m-1 w-full underline" onClick={() => setActiveContent('sustainability')}>Sostenibilidad</button>    

    /**
     * useEffect(() => {
      if (autoSwitch) {
          const intervalId = setInterval(() => {
              setActiveChart(prevChart => prevChart === 'co2' ? 'water' : 'co2');
          }, 10000); // Switch every 5000 milliseconds (5 seconds)

          return () => clearInterval(intervalId); // Clean up the interval on component unmount
      }
    }, [autoSwitch]);
     */
    useEffect(() => {
      if (autoSwitch) {
          const intervalId = setInterval(() => {
              setProgress(prevProgress => {
                  if (prevProgress < 100) {
                      return prevProgress + 1; // Increment progress
                  } else {
                      setActiveChart(prevChart => prevChart === 'co2' ? 'water' : 'co2'); // Switch chart
                      return 0; // Reset progress
                  }
              });
          }, 100); // Update progress every 100 milliseconds

          return () => clearInterval(intervalId);
      }
  }, [autoSwitch, activeChart]);

  const progressBarWidth = (chartName) => {
    return activeChart === chartName ? `${progress}%` : '0%';
  };

    const renderChart = () => {
        switch (activeChart) {
          case 'co2':
            return <ChartBarCO2 />;
          case 'water':
            return <ChartBar jsonFilePath={jsonWater}/>;
          default:
            return null; // Or a default component/view
        }
    };

    const handleChartChange = (chartType) => {
        setAutoSwitch(false); // Stop automatic switching when a button is clicked

        setActiveChart(chartType);
        if (chartType === 'co2') {
            setInfoText("El consumo de producto local y de temporada permite tener una menor emisión de CO2 en la producción y transporte. El producto local viaja mucha menor distancia.");
        } else if (chartType === 'water') {
            setInfoText("Consumimos menos agua que nuestros competidores gracias a la mejora en las técnicas de riego y exhaustivos controles de crecimiento de la planta.");
        }

        // Set a timeout to re-enable automatic switching
        setTimeout(() => {
          setAutoSwitch(true);
      }, 7000); // Re-enable autoSwitch after 10 seconds
    };

    return (
      <div  className=" flex flex-col space-y-2 md:space-y-4 items-center w-full">
        <div className="flex justify-around space-x-6 w-full ">
          <button className="relative group font-medium hover:text-curious-blue-500 transition-colors text-curious-blue-600  w-full md:text-lg" onClick={() => handleChartChange('co2')}>CO2
          <span className='h-1 block w-full mt-1 group-hover:bg-curious-blue-400 absolute bg-curious-blue-500/50' />
          <span style={{ width: progressBarWidth('co2') }} className='h-1 block w-1/2 mt-1 absolute bg-green-haze-500' /> 
          </button>
          <button className="relative group font-medium hover:text-curious-blue-500 transition-colors text-curious-blue-600  w-full md:text-lg" onClick={() => handleChartChange('water')}>HUELLA HÍDRICA
            <span className='h-1 block w-full mt-1 group-hover:bg-curious-blue-400 absolute bg-curious-blue-500/50' />
          <span style={{ width: progressBarWidth('water') }} className='h-1 block w-1/3 mt-1 absolute bg-green-haze-500' /> 
            </button>
        </div>
        <div className="flex justify-center w-full">
            <div className="flex flex-col items-start space-y-2 py-2 m-1 w-full">
                <span className="block text-green-haze-800 font-bold text-xs uppercase">Emisiones CO2</span>
            <div>
              <span className="text-green-haze-800 font-bold text-2xl lg:text-4xl">0,29<span className="text-base align-baseline">kg</span></span>
                </div>
            </div>
            <div className="flex flex-col items-start space-y-2 py-2 m-1 w-full">
                <span className="text-left block text-green-haze-800 font-bold text-xs uppercase">Consumo de agua</span>
                <div>

                <span className="text-green-haze-800 font-bold text-2xl lg:text-4xl">300<span className="text-base align-baseline">L</span></span>
                </div>
            </div>
        </div>
        <div className="bg-white  p-2 h-80 vertical-sm:h-60 w-full rounded-xl"> {/**w-96 h-80 */}
            {renderChart()}
        </div>
        <p className="text-xs sm:text-base text-green-haze-800  text-left leading-normal">
            {infoText}
        </p>
      </div>
    );
  };

  const RightPanel = () => {
    return (
      <div className="w-full h-full flex flex-col  justify-center text-left font-sans space-y-4 md:space-y-6">
        <h3>
            Compra tus productos de manera sostenible y de la mejor calidad
        </h3>
        <ul className="list-outside pl-6 list-disc space-y-2 md:space-y-4 text-base">
          <li>
              Date cuenta cuando tus productos son de temporada
          </li>
          <li>
              Menor impacto ambiental
          </li>
          <li>
              Compra productos locales
          </li>
        </ul>
        <p className="font-sans text-base">Sabor, calidad, y compromiso ambiental. Todo en <a href="https://guacaguaca.com" className="font-bold text-curious-blue-600 decoration-curious-blue-500/50 transition-all decoration-2 underline-offset-4 hover:decoration-4 underline">guacaguaca.com</a>.</p>
      </div>
    );
  };

  const Catchphrase = () => {
    return (
      <div className="text-center mt-4">
        <p>Sabor, calidad, y compromiso ambiental. Todo en <a href="https://guacaguaca.com" className="text-curious-blue-600 underline">guacaguaca.com</a>.</p>
      </div>
    );
  };
  


const Guacaguaca = () => {
  return (
        <div id="caso de uso" className="h-screen justify-center flex flex-col py-10 md:py-20 w-full">
    <div className='flex md:flex-row w-full p-2 items-center flex-col-reverse container transition-[max-width] px-5 md:px-10 duration-500 gap-y-8 md:gap-y-0 md:space-x-8 mx-auto'>
        <LeftPanel />        
        <RightPanel />    
      </div>
    </div>
  )
}

export default Guacaguaca